import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { Link, useRecordContext } from 'react-admin'
import config from '../config'

export const IpfsLinkField = memo(({ className, source, ...rest }) => {
  const record = useRecordContext()
  const cid = record[source]
  const link = `${config.ipfsGatewayUrl}/${cid}`

  const handleOpenNewTab = () => {
    const newTab = window.open(link, '_blank')
    if (newTab) {
      // The new tab was successfully opened
      // You can perform additional actions if needed
    } else {
      // The new tab was blocked by the browser's popup blocker
      // Handle this case or provide a user message
      window.alert("The new tab was blocked by the browser's popup blocker")
    }
  }

  return (
    <>
      <span
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={handleOpenNewTab}
      >
        <Link className={className}>{record[source]}</Link>
      </span>
    </>
  )
})

IpfsLinkField.propTypes = {
  record: PropTypes.object,
  className: PropTypes.string,
  source: PropTypes.string,
}

IpfsLinkField.defaultProps = {
  addLabel: true,
  source: 'cid',
}
