import {
  useTranslate,
  Create,
  FileInput,
  SimpleForm,
  FunctionField,
} from 'react-admin'
import { Title } from '../common'

const SongTitle = () => {
  const translate = useTranslate()
  const resourceName = translate('resources.song.name', {
    smart_count: 1,
  })
  const title = translate('ra.page.create', {
    name: `${resourceName}`,
  })
  return <Title subTitle={title} />
}

const SongCreate = (props) => {
  return (
    <Create title={<SongTitle />} {...props}>
      <SimpleForm redirect="list" variant={'outlined'}>
        <FileInput
          source="file"
          maxSize={10000000}
          multiple={false}
          accept={'.mp3,.m4a'}
        >
          <FunctionField render={(record) => record.rawFile.name} />
        </FileInput>
      </SimpleForm>
    </Create>
  )
}

export default SongCreate
