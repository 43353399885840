import React from 'react'
import { Route } from 'react-router-dom'
import Personal from './personal/Personal'
import Wallet from './wallet/Wallet'

const routes = [
  <Route exact path="/personal" render={() => <Personal />} />,
  <Route exact path="/wallet" render={() => <Wallet />} />,
]

export default routes
