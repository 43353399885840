import { Title, useTranslate } from 'react-admin'
import {
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CopyToClipboardButton from './CopyToClipboardButton'
const useStyles = makeStyles({
  root: { marginTop: '1em' },
  truncateText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
})

const Wallet = () => {
  const translate = useTranslate()
  const classes = useStyles()

  return (
    <Card className={classes.root}>
      <Title title={'EpicSoundTech - ' + translate('menu.wallet.name')} />
      <TableContainer component={Paper}>
        <Table aria-label={translate('menu.wallet.name')}>
          <TableBody>
            <TableRow>
              <TableCell align="right" component="th">
                {translate('wallet.address')} :
              </TableCell>
              <TableCell className={classes.truncateText}>
                <CopyToClipboardButton />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="right" component="th">
                {translate('wallet.balance')} :
              </TableCell>
              <TableCell>0</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

export default Wallet
