import { Snackbar } from '@mui/material'
import { useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IconButton } from '@mui/material'
const CopyToClipboardButton = () => {
  const [open, setOpen] = useState(false)
  const address = localStorage.getItem('wallet-address')
  const handleClick = () => {
    setOpen(true)
    navigator.clipboard.writeText(address)
  }

  return (
    <>
      <div>
        {address ?? ''}
        <IconButton onClick={handleClick} color="primary" size="small">
          <ContentCopyIcon />
        </IconButton>
      </div>
      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        autoHideDuration={2000}
        message="Copied to clipboard"
      />
    </>
  )
}

export default CopyToClipboardButton
